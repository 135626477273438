<script>
  import { fade } from 'svelte/transition';
  import { activities, isFetching, selectedDate } from '../lib/store.js';
  import ActivitiesListItem from './ActivitiesListItem.svelte';
  import DayOverview from './DayOverview.svelte';
</script>

{#if $activities.length}
  <section name="activities_list">
    <h2 class:is_fetching={$isFetching}>
      Aktivitäten am
      {new Intl.DateTimeFormat('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      }).format($selectedDate)}
    </h2>
    <DayOverview />
    <ul class="activities" transition:fade={{ delay: 250, duration: 300 }}>
      {#each $activities as activity, i}
        <ActivitiesListItem {activity} {i} />
      {/each}
    </ul>
  </section>
{/if}
