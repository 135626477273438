<script>
  import { log } from '../lib/log.js';
  import { API_URL } from '../lib/api.js';
  import {
    user,
    selectedActivity,
    activities,
    projects,
    fetchActivities,
    selectedDate,
  } from '../lib/store.js';
  // import { createForm } from 'svelte-forms-lib';
  import * as yup from 'yup';

  Date.prototype.toDateInputValue = function () {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const activitySchema = yup.object().shape({
    activities_unique_id: yup.string(),
    users_unique_id: yup.string().required(),
    projects_unique_id: yup.string().required(),
    start_date: yup.string().required(),
    end_date: yup.string().required(),
  });

  // const errors = {
  //   projects_unique_id: [],
  //   start_date: [],
  //   end_date: [],
  // };

  let activity = {
    activities_unique_id: null,
    projects_unique_id: null,
    start_date: new Date($selectedDate).toDateInputValue(),
    start_time: null,
    end_time: null,
    project_id: null,
  };

  async function handleSubmit() {
    const payload = {
      users_unique_id: $user?.unique_id,
      activities_unique_id: activity.activities_unique_id || '',
      projects_unique_id: activity.projects_unique_id,
      start_date: `${activity.start_date} ${activity.start_time}`,
      end_date: `${activity.start_date} ${activity.end_time}`,
    };

    log(payload);

    activitySchema
      .validate(payload, { abortEarly: false })
      .then(async () => {
        await storeActivity(payload);
        await fetchActivities($user.unique_id, new Date());
      })
      .catch(console.log);
  }

  async function storeActivity(payload) {
    const method = payload.activities_unique_id ? 'PATCH' : 'POST';
    const url =
      method === 'PATCH'
        ? `${API_URL}/activities/${payload.activities_unique_id}`
        : `${API_URL}/activities`;

    let res = await fetch(url, {
      method: method,
      body: JSON.stringify(payload),
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const result = await res.json();
  }

  $: $selectedActivity = $activities.find(
    (act) => act.activities_unique_id === $selectedActivity
  );

  $: (() => {
    if ($selectedActivity && $selectedActivity.activities_unique_id) {
      // Prepare and fill in all values:
      const start_date = new Date($selectedActivity.start_date_tz);
      const end_date = new Date($selectedActivity.end_date_tz);
      activity.activities_unique_id = $selectedActivity.activities_unique_id;
      activity.projects_unique_id = $selectedActivity.projects_unique_id;
      activity.start_date = `${start_date.getFullYear()}-${
        start_date.getMonth() + 1
      }-${start_date.getDate()}`;
      activity.start_time = `${('0' + start_date.getHours()).slice(-2)}:${(
        '0' + start_date.getMinutes()
      ).slice(-2)}`;
      activity.end_time = `${('0' + end_date.getHours()).slice(-2)}:${(
        '0' + end_date.getMinutes()
      ).slice(-2)}`;
    } else {
      // Reset the form:
      activity = [];
    }
  })();

  $: (() => {
    // Once a calendar date has been clicked, we'll update the form.
    const selected_date = new Date($selectedDate);
    const selected_year = selected_date.getFullYear();
    const selected_month = ('0' + (selected_date.getMonth() + 1)).slice(-2);
    const selected_day = ('0' + selected_date.getDate()).slice(-2);
    const start_date = `${selected_year}-${selected_month}-${selected_day}`;
    activity.start_date = start_date;
  })();

  // function validateActivity(activity) {
  //   const start_date_obj = new Date(
  //     `${activity.start_date}T${activity.start_time}`
  //   );
  //   const end_date_obj = new Date(
  //     `${activity.start_date}T${activity.end_time}`
  //   );

  //   if (!start_date_obj) {
  //     errors.start_date.push('INVALID_DATE');
  //   }

  //   if (!end_date_obj) {
  //     errors.end_date.push('INVALID_DATE');
  //   }

  //   if (
  //     start_date_obj &&
  //     end_date_obj &&
  //     start_date_obj.getTime() > end_date_obj.getTime()
  //   ) {
  //     errors.start_date.push('INVALIDE_DATE_RANGE');
  //     errors.end_date.push('INVALIDE_DATE_RANGE');
  //   }

  //   if (!activity.projects_unique_id) {
  //     errors.projects_unique_id.push('INVALID_PROJECT');
  //   }

  //   return errors;
  // }
</script>

<section name="edit-activities">
  <h2>Arbeitszeiten erfassen</h2>
  <form name="activity" id="activity" on:submit|preventDefault={handleSubmit}>
    <input
      name="activities_unique_id"
      type="hidden"
      bind:value={activity.activities_unique_id} />
    <div class="formfield">
      <select
        required="true"
        name="projects_unique_id"
        bind:value={activity.projects_unique_id}>
        <option>Projekt auswählen …</option>
        {#each $projects as project}
          <option value={project.unique_id}>
            <span>[{project.shortcode}]</span>
            {project.project}
          </option>
        {/each}
      </select>
    </div>
    <div class="formfield three">
      <p class="start_date">
        {new Intl.DateTimeFormat('de-DE', {
          weekday: 'short',
        }).format(new Date($selectedDate))},
        {new Intl.DateTimeFormat('de-DE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }).format(new Date($selectedDate))}
      </p>
      <input
        required="true"
        name="start_time"
        id="start_time"
        type="time"
        bind:value={activity.start_time}
        placeholder="Startzeit" />
      <input
        required="true"
        name="end_time"
        id="end_time"
        type="time"
        bind:value={activity.end_time}
        placeholder="Endzeit" />
      <button class="submit" type="submit">→</button>
    </div>
  </form>
</section>
