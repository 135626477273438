<script>
  import {
    fetchMonthlyProjectSums,
    projects,
    selectedDate,
  } from '../lib/store';
  import { formatTime } from '../lib/utils';

  const selected_date = new Date($selectedDate);
  const start_date = new Date(
    selected_date.getFullYear(),
    selected_date.getMonth(),
    1
  );
  const end_date = new Date(
    selected_date.getFullYear(),
    selected_date.getMonth() + 1,
    0
  );
</script>

<section name="projects_daily_sums">
  <h2>
    Projektsummen im
    {new Intl.DateTimeFormat('de-DE', {
      month: 'long',
      year: 'numeric',
    }).format($selectedDate)}
  </h2>
  <ul class="projects">
    {#each $projects as project, i}
      <li class="project" data-index={i}>
        {#await fetchMonthlyProjectSums(project.unique_id, start_date, end_date)}
          <p>… wird geladen</p>
        {:then sum}
          <span>
            <span
              class="shortcode marker-{project.marker?.toLowerCase()}">{project.shortcode}</span>
            <span class="project">{project.project}</span></span>
          <span class="sum">&nbsp; {formatTime(sum.seconds / 60)} h </span>
        {/await}
      </li>
    {/each}
  </ul>
</section>
