<script>
  /**
   * tempo
   * © 2020, Hendrik Runte
   **/

  export let users_unique_id;

  import { version } from './lib/version.js';
  import { log } from './lib/log.js';

  import { onMount } from 'svelte';

  import {
    fetchUser,
    fetchActivities,
    fetchProjects,
    user,
  } from './lib/store.js';

  // import Login from './components/Login.svelte';
  import Calendar from './components/Calendar.svelte';
  import EditActivities from './components/EditActivities.svelte';
  import ActivitiesList from './components/ActivitiesList.svelte';
  import ProjectsDailySums from './components/ProjectsDailySums.svelte';
  import ProjectsMonthlySums from './components/ProjectsMonthlySums.svelte';
  import Revision from './components/Revision.svelte';

  // const users_unique_id = '9e0d57d2-29af-11eb-88ec-20e2a8b7de8d';

  // const DEBUG = TEMPO.env.isProd ? false : true;
  // const DEBUG = true;
  // const log = DEBUG ? console.log.bind(console) : function () {};

  log(
    '%cThis is the Tempo client. It`s assembled with svelte.',
    'background-color: fuchsia; color: black; padding: 0 1em'
  );

  log(
    '%cMODE: %s',
    'background-color: fuchsia; color: black; padding: 0 1em',
    TEMPO.env.isProd ? 'PRODUCTION' : 'DEV'
  );

  log(
    '%cAPI_HOST: %s',
    'background-color: fuchsia; color: black; padding: 0 1em',
    TEMPO.env.API_HOST
  );

  onMount(async () => {
    await fetchUser(users_unique_id);
    await fetchProjects(users_unique_id);
    await fetchActivities(users_unique_id, new Date());
  });
</script>

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">
</style>

<header>
  <h1>
    <span>Tempo</span>
    <span>{#if users_unique_id}{$user.firstname} {$user.lastname}{/if}</span>
  </h1>
</header>
<main>
  {#if users_unique_id}
    <Calendar />
    <EditActivities />
    <ActivitiesList />
    <ProjectsDailySums />
    <ProjectsMonthlySums />
  {:else}
    <div class="alert">
      <p>Um fortzufahren, wird eine gültige Benutzer-ID benötigt.</p>
    </div>
  {/if}
</main>
<Revision {version} />
