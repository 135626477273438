<script>
  export let selectedYear = new Date().getFullYear();
  export let selectedMonth = new Date().getMonth();

  import {
    user,
    fetchActivities,
    selectedDate,
    selectedActivity,
  } from '../lib/store.js';

  let Days = [];
  let PreviousDays = [];
  let FollowingDays = [];
  let Weeks = [[]];
  let firstWeekStartsWithDay = new Date();
  let firstDayOfWeek = 1; // Monday;
  let lastDayOfWeek = firstDayOfWeek - 1; // Sunday
  let lastWeekEndsWithDay = null;
  let monthName = '';

  /**
   * Returns the number of days in a given month.
   * To avoid irritations with 1-based months or 0-based months.
   * Inserts 2000 if before 1000 AD.
   * Months are 1-based.
   * @param  {String} month_name 'jan' | 'feb' | 'mar' ...
   * @param  {Number} fullYear   2001 | 2002 ...
   */
  function howManyDaysInMonth(month, fullYear) {
    return new Date(2000 + (fullYear % 2000), month + 1, 0).getDate();
  }

  /**
   * Sorts days out by creating an array of dates: #Days.
   * In order to be able to draw a table with one month and its weeks
   * we need to determine the weekday of the first of the month
   * and the date of the last sunday of the month.
   */
  function getMonthsDays(fullYear, month) {
    // At first, we'll fill the #Days array with all days belonging to the selected #Month.
    let daysInMonth = howManyDaysInMonth(month, fullYear);

    let i = 0;
    let max_i = daysInMonth;
    for (i = 0; i < max_i; i += 1) {
      Days[i] = new Date(fullYear, month, i + 1);
    }

    firstWeekStartsWithDay = new Date(
      2000 + (fullYear % 2000),
      month,
      1 - (Days[0].getDay() || 7) + firstDayOfWeek
    );

    lastWeekEndsWithDay = new Date(
      2000 + (fullYear % 2000),
      month + 1,
      7 - Days[Days.length - 1].getDay()
    );

    // There are some days being part of the previous month but deserve to be displayed.
    // We'll stuff them into PreviousDays.
    // But only if the first month's day is not a monday.
    if (Days[0].getDay() !== firstDayOfWeek) {
      let k = firstWeekStartsWithDay.getDate();
      let max_k = new Date(2000 + (fullYear % 2000), month, 0).getDate();
      for (k; k <= max_k; k += 1) {
        PreviousDays.push(new Date(2000 + (fullYear % 2000), month - 1, k));
      }
    }

    // According to PreviousDays there are FollowingDays
    // but only if the last day is not the last day of the week.
    if (Days[Days.length - 1].getDay() !== lastDayOfWeek) {
      let m = 1;
      let max_m = lastWeekEndsWithDay.getDate();
      for (m = 1; m <= max_m; m += 1) {
        FollowingDays.push(new Date(2000 + (fullYear % 2000), month + 1, m));
      }
    }

    monthName = new Date(
      2000 + (fullYear % 2000),
      month,
      1
    ).toLocaleDateString('de-DE', { month: 'long' });
  }

  function initCalendar() {
    Days = [];
    PreviousDays = [];
    FollowingDays = [];
    Weeks = [[]];
  }

  /**
   * Providing we already have set up the #Days arrays
   * we split all days into #Weeks.
   */
  function getMonthsWeeks(fullYear, month) {
    getMonthsDays(fullYear, month);
    // This renders the first week, which may be half in the previous month.
    let p = 0;
    let max_p = PreviousDays.length;

    for (p = 0; p < max_p; p += 1) {
      Weeks[0][p] = PreviousDays[p];
    }
    // Start collecting the first week's days.
    let q = 0;
    let max_q = 7 - p;

    for (q = 0; q < max_q; q += 1) {
      Weeks[0][p + q] = Days[q];
    }
    // Now we split all remaining days into weeks.
    let s = 0;
    let w = 0;
    let r = 0;
    let max_r = Days.length;

    for (r = q; r < max_r; r += 1) {
      if (s % 7 === 0) {
        w += 1;
        Weeks[w] = [];
      }
      Weeks[w].push(Days[r]);
      s += 1;
    }
    // And at the end we append the FollowingDays.
    for (let t = 0, max_t = FollowingDays.length; t < max_t; t += 1) {
      Weeks[w].push(FollowingDays[t]);
    }
  }

  Date.prototype.getWeekNumber = function (dowOffset) {
    dowOffset = typeof dowOffset == 'int' ? dowOffset : 0; //default dowOffset to zero
    let newYear = new Date(this.getFullYear(), 0, 1);
    let day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = day >= 0 ? day : day + 7;
    let daynum =
      Math.floor(
        (this.getTime() -
          newYear.getTime() -
          (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
          86400000
      ) + 1;
    let weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
      weeknum = Math.floor((daynum + day - 1) / 7) + 1;
      if (weeknum > 52) {
        nYear = new Date(this.getFullYear() + 1, 0, 1);
        nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
        weeknum = nday < 4 ? 1 : 53;
      }
    } else {
      weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
  };

  async function selectDate(dateObj = new Date()) {
    selectedDate.set(new Date(dateObj).setHours(0, 0, 0, 0));
    selectedActivity.set(null);
    await fetchActivities($user.unique_id, dateObj);
  }

  function selectMonth(monthOffset = 0) {
    initCalendar();
    const selectedDayOfMonth = new Date($selectedDate).getDate();
    let otherMonth = new Date(new Date().setDate(selectedDayOfMonth));
    otherMonth.setMonth(otherMonth.getMonth() + monthOffset);
    selectedMonth = new Date($selectedDate).getMonth();
    selectDate(otherMonth);
    window.console.log(`selectedMonth+1: ${selectedMonth}+1`);
    getMonthsWeeks(selectedYear, selectedMonth);
  }

  function selectPreviousMonth() {
    selectMonth(-1);
  }

  function selectNextMonth() {
    selectMonth(1);
  }

  function selectToday() {
    selectDate(new Date());
  }

  // initCalendar();
  getMonthsWeeks(selectedYear, selectedMonth);
</script>

<section name="calendar">
  <div class="calendernav">
    <h2>{monthName} {selectedYear}</h2>
    <p>
      <button class="previous_month" on:click={selectPreviousMonth} />
      <button class="today" on:click={selectToday} />
      <button class="next_month" on:click={selectNextMonth} />
    </p>
  </div>
  <table class="calender">
    <thead>
      <tr>
        <th>Mo</th>
        <th>Di</th>
        <th>Mi</th>
        <th>Do</th>
        <th>Fr</th>
        <th class="weekend">Sa</th>
        <th class="weekend">So</th>
      </tr>
    </thead>
    <tfoot />
    <tbody>
      {#each Weeks as week}
        <tr>
          {#each week as day}
            <td
              class:weekend={new Date(day).getDay() === 0 || new Date(day).getDay() === 6}>
              <a
                on:click|preventDefault={selectDate(new Date(day))}
                href="#/{new Date(day).getFullYear()}-{new Date(day).getMonth() + 1}-{new Date(day).getDate()}"
                data-selected={$selectedDate}
                data-day={new Date(new Date(day).setHours(0, 0, 0, 0)).getTime()}
                title={new Intl.DateTimeFormat('de-DE').format(new Date(day))}
                class:selected={$selectedDate === new Date(new Date(day).setHours(0, 0, 0, 0)).getTime()}
                class:othermonth={new Date(day).getMonth() !== selectedMonth}
                class:today={new Date(day).getFullYear() + '-' + (new Date(day).getMonth() + 1) + '-' + new Date(day).getDate() === new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()}>{new Date(day).getDate()}</a>
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</section>
