export function convertMinsToHrsMins(mins) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return `${h}:${m}`;
}

export function convertTimeStringToDecimal(timeString) {
  const hoursMinutes = timeString.split(':');
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return (hours + minutes / 60).toFixed(2);
}

export function formatTime(minutes) {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
  }).format(convertTimeStringToDecimal(convertMinsToHrsMins(minutes)));
}
