import App from './App.svelte';

const root = document.getElementById('tempo');
const params = new URLSearchParams(window.location.search);

export default new App({
  target: root,
  props: {
    users_unique_id: params.get('user'),
  },
});
