import { writable } from 'svelte/store';

import { API_URL } from './api.js';

export const user = writable({});
export const activities = writable([]);
export const projects = writable([]);
export const isFetching = writable(false);
export const selectedDate = writable(new Date().setHours(0, 0, 0, 0));
export const selectedActivity = writable('');
export const dailyProjectSums = writable({});

export async function fetchUser(users_unique_id) {
  isFetching.set(true);

  let res = await fetch(`${API_URL}/users/${users_unique_id}`);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  if (res.status === 200) {
    const result = await res.json();
    user.set(result.Rows[0]);
  } else {
    user.set([]);
  }

  isFetching.set(false);
}

export async function fetchProjects(users_unique_id) {
  isFetching.set(true);

  let res = await fetch(`${API_URL}/assignments/user/${users_unique_id}`);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  if (res.status === 200) {
    const result = await res.json();
    projects.set(Object.values(result.Rows));
  } else {
    projects.set([]);
  }

  isFetching.set(false);
}

export async function fetchActivities(users_unique_id, dateObj = new Date()) {
  const dateIso = `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;

  isFetching.set(true);

  let res = await fetch(
    `${API_URL}/activities/user/${users_unique_id}/${dateIso}`
  );

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  if (res.status === 200) {
    const result = await res.json();
    activities.set(Object.values(result.Rows));
  } else {
    activities.set([]);
  }

  isFetching.set(false);
}

export function fetchDailyProjectSums(_projects, _activities) {
  if (_projects.length) {
    const activitiesByProjects = {};
    const shortCodes = _projects.map((p) => p.shortcode);
    const dailySums = {};
    shortCodes.forEach((s) => {
      const sums = [];
      activitiesByProjects[s] = _activities.filter((p) => p.shortcode === s);
      activitiesByProjects[s].forEach((a) => {
        sums.push(
          new Date(a.end_date_tz).getTime() -
            new Date(a.start_date_tz).getTime()
        );
      });
      dailySums[s] = sums.reduce(function (a, b) {
        return a + b;
      }, 0);
    });
    dailyProjectSums.set(dailySums);
  }
}

export async function fetchMonthlyProjectSums(
  _projects_unique_id,
  start_date,
  end_date
) {
  isFetching.set(true);

  start_date ||= new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  end_date ||= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

  const start_date_string = start_date.toISOString().split('T')[0];
  const end_date_string = end_date.toISOString().split('T')[0];
  let res = await fetch(
    `${API_URL}/projectsums/${_projects_unique_id}?start_date=${start_date_string}&end_date=${end_date_string}`
  );

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  isFetching.set(false);

  if (res.status === 200) {
    const result = await res.json();
    return result.Rows[0];
  } else {
    return {};
  }
}

export function isQuarterDuringActivity(timestamp, _activities) {
  _activities.forEach((activity) => {
    // console.log(
    //   new Date(activity.start_date_tz).getTime(),
    //   timestamp,
    //   new Date(activity.end_date_tz).getTime()
    // );
    if (
      new Date(activity.start_date_tz).getTime() < timestamp &&
      new Date(activity.end_date_tz).getTime() > timestamp
    ) {
      return selectedActivity;
    }
  });
}
