<script>
  import { beforeUpdate, afterUpdate } from 'svelte';
  import { fade } from 'svelte/transition';
  import { activities, selectedDate } from '../lib/store.js';

  let quarterHours = [];

  const START_HOUR = 7 * 3600;
  const END_HOUR = 18 * 3600;

  function createQuarterHours(startTime = START_HOUR, endTime = END_HOUR) {
    let q = [];
    for (let i = startTime, max_i = endTime; i <= max_i; i += 900) {
      q.push({
        secondsAfterMidnight: i,
        timestamp: new Date($selectedDate).setHours(0, 0, 0, 0) + i * 1000,
      });
    }
    return q;
  }

  function activityDuringQuarter(quarter_hour, _activities) {
    const quarter_hour_time = new Date(quarter_hour).getTime();
    if (_activities.length) {
      const matches = _activities.filter(
        (act) =>
          new Date(act.start_date_tz).getTime() <= quarter_hour_time &&
          quarter_hour_time < new Date(act.end_date_tz).getTime()
      );
      return matches[0];
    }
    return undefined;
  }

  function getNiceHoursAndMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    let minutes = (seconds / 60) % 60;
    minutes = ':' + ('0' + minutes).slice(-2);
    return hours + minutes;
  }

  beforeUpdate(() => {
    quarterHours = createQuarterHours();
  });

  afterUpdate(() => {
    quarterHours = [];
  });
</script>

<div class="subsection" name="day_overview">
  <ul class="dayoverview" transition:fade={{ delay: 350, duration: 300 }}>
    {#each quarterHours as quarter, i}
      <li
        data-index={i}
        data-timestamp={quarter.timestamp}
        title={activityDuringQuarter(quarter.timestamp, $activities)?.project || ''}
        class="marker-{activityDuringQuarter(quarter.timestamp, $activities)?.marker?.toLowerCase() || 'void'}"
        style="width: {100 / quarterHours.length}%">
        <span>{getNiceHoursAndMinutes(quarter.secondsAfterMidnight)}</span>
      </li>
    {/each}
  </ul>
</div>
