<script>
  export let activity;
  export let i;

  import {
    selectedActivity,
    selectedDate,
    user,
    fetchActivities,
  } from '../lib/store.js';
  import { API_URL } from '../lib/api.js';

  const dateOptions = {
    start_date: {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    end_date: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  };

  function selectActivity(activities_unique_id) {
    selectedActivity.set(activities_unique_id);
  }

  async function deleteActivity(activities_unique_id) {
    if (!activities_unique_id) {
      return false;
    }

    if (confirm('Möchtest Du diesen Eintrag wirklich löschen?')) {
      let res = await fetch(`${API_URL}/activities/${activities_unique_id}`, {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      if (res.status === 200) {
        // Trigger view update.
        await fetchActivities($user.unique_id, new Date($selectedDate));
      }

      const result = await res.json();
    }
  }
</script>

<li
  data-activities_unique_id={activity.activities_unique_id}
  data-activity_index={i}
  class="marker-{activity.marker?.toLowerCase()}">
  <a
    href="#/activity/{activity.activities_unique_id}"
    on:click|preventDefault={selectActivity(activity.activities_unique_id)}
    class="activity item"
    data-selected={$selectedActivity ? $selectedActivity.activities_unique_id : null}
    class:selected={$selectedActivity ? $selectedActivity.activities_unique_id === activity.activities_unique_id : false}
    title="Diese Aktivität auswählen zur Bearbeitung.">
    <span
      class="shortcode marker-{activity.marker?.toLowerCase()}">{activity.shortcode}</span>
    <span class="dates">
      <span
        class="start_date">{new Intl.DateTimeFormat('de-DE', dateOptions.start_date).format(new Date(activity.start_date_tz))},</span>
      <span
        class="start_time">{new Intl.DateTimeFormat('de-DE', dateOptions.end_date).format(new Date(activity.start_date_tz))}</span>
      <span class="dash">–</span>
      <span
        class="end_time">{new Intl.DateTimeFormat('de-DE', dateOptions.end_date).format(new Date(activity.end_date_tz))}</span>
      <span
        class="timespan">{((new Date(activity.end_date_tz).getTime() - new Date(activity.start_date_tz).getTime()) / 900000) * 15}</span>
    </span>
  </a>
  <a
    href="#/activity/{activity.activities_unique_id}"
    on:click|preventDefault={deleteActivity(activity.activities_unique_id)}
    class="delete">…</a>
</li>
