<script>
  import {
    projects,
    activities,
    fetchDailyProjectSums,
    dailyProjectSums,
    selectedDate,
  } from '../lib/store';

  import { formatTime } from '../lib/utils';

  $: fetchDailyProjectSums($projects, $activities);
</script>

{#if $activities.length}
  <section name="projects_daily_sums">
    <h2>
      Projektsummen am
      {new Intl.DateTimeFormat('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      }).format($selectedDate)}
    </h2>
    <ul class="projects">
      {#each $projects as project, i}
        {#if $dailyProjectSums[project.shortcode] > 0}
          <li class="project" data-index={i}>
            <span>
              <span
                class="shortcode marker-{project.marker?.toLowerCase()}">{project.shortcode}</span>
              <span class="project">{project.project}</span></span>
            <span class="sum">&nbsp;
              {formatTime(($dailyProjectSums[project.shortcode] / 900000) * 15)}
              h
            </span>
          </li>
        {/if}
      {/each}
    </ul>
  </section>
{/if}
